<template>
	<div class="three_cross_static report_content">
		<div class="filter">
			<div class="search">
				<el-date-picker
					size="small"
					v-model="year"
					type="year"
					value-format="yyyy"
					placeholder="选择年度"
					:clearable="false"
					style="width:118px;"
					@change="getList"
				></el-date-picker>

				<div class="search_item">
					<div class="label">所属地区</div>
					<el-select
						size="small"
						v-model="area"
						style="width:118px;"
						clearable
						@change="getList"
					>
						<el-option
							v-for="item in areaList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</div>
			</div>

			<div class="right">
				<el-button
					size="small"
					type="primary"
					class="button_98px"
					@click="addReport"
					>添加报备
				</el-button>
				<el-button
					size="small"
					type="primary"
					class="button_98px"
					@click="exportForm"
					>导出表格
				</el-button>
			</div>
		</div>
		<div class="content">
			<h3>援青交往、交流、交融项目实施情况汇总表</h3>
			<div class="report_table">
				<div class="table">
					<el-table :data="tableData" style="width: 100%;" height="100%">
						<el-table-column label="月份" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.month == 13">合计</div>
								<div v-else>{{ scope.row.month }}月</div>
							</template>
						</el-table-column>
						<el-table-column
							label="当月计划数（批次）"
							prop="plan_num"
							align="center"
						></el-table-column>
						<el-table-column
							label="实际完成数（批次）"
							prop="real_finish_num"
							align="center"
						></el-table-column>
						<el-table-column label="参加人数" align="center">
							<el-table-column
								label="计划数（人）"
								prop="plan_people_num"
								align="center"
							></el-table-column>
							<el-table-column
								label="实际数（人）"
								prop="real_people_num"
								align="center"
							></el-table-column>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api/methods/index";
import { areaList } from "@/variable/area.js";

export default {
	data() {
		return {
			year: "",
			area: "",
			tableData: [],
		};
	},
	computed: {
		areaList() {
			return areaList;
		},
	},
	created() {
		this.year = new Date().getFullYear().toString();
		this.getList();
	},
	methods: {
		getList() {
			this.tableData = [];
			api.threeCross
				.threeCrossStatic({ year: this.year, area: this.area })
				.then((res) => {
					let list = [];
					if (res.data && res.data.data) {
						list = res.data.data || [];
					}
					this.tableData = list;
				});
		},
		exportForm() {
			let param = { year: this.year };
			let _url = window.location.href;
			let __url = _url.split("#")[0];
			let url =
				__url +
				"index.php/cqh/Threeproject/report_statistics_out/?token=" +
				this.$store.state.user.token +
				"&year=" +
				param.year;
			api.threeCross.threeCrossStaticReport(param).then((res) => {
				dd.biz.util.openLink({
					url, //要打开链接的地址
					onSuccess: function(result) {
						console.log("打开导出地址成功");
					},
					onFail: function(err) {
						console.log("打开导出地址失败", err);
					},
				});
			});
		},
		addReport() {
			this.$router.push({ path: "/pc/three_cross/report" });
		},
	},
};
</script>

<style lang="less" scoped>
.search_item {
	margin-left: 20px;
	display: flex;
	align-items: center;
	.label {
		margin-right: 10px;
	}
}
</style>
